<template>
    <div style="width:100%">
        <inner-top-nav></inner-top-nav>
        <div style="font-size: 52px; color:red">运维看板</div>
        <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in Math.ceil(menu.length/4)" :key="index">
            <!-- <el-carousel-item v-for="(item,index) in menu.length" :key="index"> -->
                <!-- ceil 对数向上舍入  for (let i = 0; i < menu.length; i += 4) -->
                <div class="flex">
                    <div class="box1 bgc_03CABE mg_left_30" v-for="(items,index) in menu.slice(index*Math.ceil(menu.length/4),index*Math.ceil(menu.length/4)+4)"
                        :key="index">
                        <div class="mg_left_30">
                            <div>{{items}}</div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

    </div>
</template>
<script>
    import innerTopNav from './../../components/innerTopNav'
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                alarmIndex: 1,
                left: 0,
                menu: ['第1', '第2', '第3', '第4', '第5', '第6', '第7','8','9','10','11','12','13']
            }
        },

        mounted() {},
        methods: {
            handleClickToRight() {
                // if (!this.rightUse) {
                //     return;(
                // }

                if (this.left > (this.$refs.outBox.clientWidth - this.$refs.box.clientWidth) && this.left <= 0) {
                    // this.left -= this.$refs.box.clientWidth / this.$refs.box1.clientWidth;
                    this.left -= this.$refs.box1.clientWidth;
                } else {
                    this.left = (this.$refs.outBox.clientWidth - this.$refs.box.clientWidth);
                }
            },
            handleClickToLeft() {
                if (this.left < 0) {
                    this.left += this.$refs.box1.clientWidth;
                } else {
                    this.left = 0;
                    this.leftUse = false;
                }
            }
        }
    }
</script>
<style scoped>
    .box1 {
        width: 278px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tags-box {
        display: inline-flex;
        position: relative;
        text-align: left;
        /* transition: .3s; */
    }
</style>